#orderStatus-card ion-datetime {
  padding: 0;
}
#orderStatus-card ion-col:nth-child(1) {
  padding-left: 0;
}
#orderStatus-card-empty {
  min-height: 14.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
