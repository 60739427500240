table {
  margin: 1rem 0 2rem;
  border: 1px solid;
  border-color: var(--ion-color-light);
  width: 100%;
  text-align: left;
  position: relative;
  border-collapse: separate; /*to check*/
}
.no-style td {
  padding: 0;
}
.no-style {
  margin: 0;
  border: 0;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 0;
}
.tablename {
  padding: 0 1rem;
}
table thead th {
  padding-bottom: 1rem;
  border-bottom: 1px solid;
  border-color: var(--ion-color-light);
}
table thead th,
table tbody td {
  padding: 0.8rem;
}
td.actions > tr > td {
  padding: 0;
}
table thead th {
  padding-bottom: 1rem;
}
table .stripped {
  background-color: var(--ion-color-light);
}
table th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table th.actions {
  padding-left: 2.2rem;
}
table tr {
  cursor: pointer;
}
table .actions {
  position: sticky;
  right: 0;
  border-left: 1px solid;
  border-color: var(--ion-color-light);
  background: var(--ion-background-color);
}
table .stripped .actions {
  background-color: var(--ion-color-light);
}
ion-button ion-icon {
  margin-right: 0.2rem;
}
h2 {
  margin: 0;
}
.theader {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--ion-color-medium);
}
.table ion-icon {
  margin-right: 0.2rem;
}
