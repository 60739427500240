.product-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1;
  background: var(--ion-background-color);
  top: 0;
}
.total-price {
  font-size: 1rem;
}
