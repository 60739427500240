.product-card-header {
  display: flex;
  justify-content: space-between;
}
.product-actions {
  border-top: 1px solid;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-color: var(--ion-color-light);
  display: flex;
  justify-content: space-between;
}
.icon-header {
  height: 24px;
  width: 24px;
  margin-left: 0.3rem;
}
.success {
  color: var(--ion-color-success);
}
.warning {
  color: var(--ion-color-warning);
}
.product-card-title {
  display: flex;
  align-items: center;
}

.offerFormCard{
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  box-shadow: none;
  margin:0
}
.offerFormCardExpanded{margin:0;
  box-shadow: none;
  max-height: 800px;
  transition: max-height 0.25s ease-in;

}