.modal-fullscreen .modal-wrapper  {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
}
.modal-fullscreen .ion-page{
  display:block;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal.modal-fullscreen {
    --width: 100%;
    --height: 100%;
  }
}