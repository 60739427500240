/*Custom styles*/
hr {
  background: var(--ion-color-light-tint);
}
small {
  color: var(--ion-color-medium);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.3rem 0;
}

/* .testechip {
  --background: green;
  --color: white;
} */

#details-feature ion-card.ios {
  margin: 0.5rem;
  padding-bottom: 1rem;
  height: 100%;
}
#details-feature ion-card-content.ios.card-content-ios {
  padding: 0;
}
#details-feature ion-row.ios {
  margin: 1rem 0;
}
ion-card .list-ios {
  background: none;
}
.sc-ion-alert-ios-h {
  --max-width: 50%;
  --min-width: 50%;
}
/* Without setting height the slides will take up the height of the slide's content */
ion-slides {
  height: 100%;
}

.ios {
  /* pointer-events: auto !important;      */
}
ion-content {
  pointer-events: auto;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
