.align-center {
  display: flex;
  align-items: center;
}
.align-center h1 {
  margin: 0;
  margin-right: 0.5rem;
  padding: 1rem;
}
.align-center.right {
  justify-content: space-between;
}

.align-center ion-icon {
  margin-right: 0.5rem;
}

.userDetails-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userDetails-header h1 {
  margin: 0;
}
