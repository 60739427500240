.rbc-toolbar button {
  border: 1px solid var(--ion-color-primary) !important;
  color: var(--ion-color-primary) !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: var(--ion-color-primary) !important;
  border-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-time-content > * + * > *,
.rbc-time-header-content {
  border-left: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
}

.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot,
.rbc-time-content,
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
}

.rbc-off-range-bg {
  background: var(--ion-color-light) !important;
}

.rbc-current-time-indicator {
  background-color: var(--ion-color-primary) !important;
}

.rbc-header + .rbc-header,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
}
.rbc-header,
.rbc-timeslot-group {
  border-bottom: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
}

button.rbc-active:focus,
button.rbc-active:hover,
button.rbc-active:active {
  outline: 0;
  color: var(--ion-color-primary-contrast) !important;
}
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
  margin: 0;
}
.rbc-today {
  background-color: var(--ion-color-dark-contrast) !important;
}
.rbc-month-view,
.rbc-time-view {
  border: 1px solid !important;
  border-color: var(--ion-color-light-tint) !important;
  border-radius: 8px;
}
.rbc-calendar {
  padding: 0 1rem 1rem;
  width: 100%;
  height: 100vh !important;
}
.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  outline: 0;
  background-color: var(--ion-color-primary-tint) !important;
  border-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
}

.rbc-time-view .rbc-allday-cell {
  padding: 1rem 0 0 !important;
}
.rbc-header + .rbc-header,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  /* color: var(--ion-color-primary-contrast) !important; */
}

.details-filter {
  width: 100%;
  padding-left: 0.2rem;
  border: 1px solid;
  border-color: var(--ion-color-light-tint);
  border-radius: 8px;
  margin: 0 0.5rem 1rem;
}
.details-label {
  position: absolute;
  top: -0.5rem;
  font-size: 0.8rem;
  font-style: italic;
  background: var(--ion-background-color);
  color: var(--ion-color-medium);
}

.goToMapButton {
  position: relative;
  top: -0.4rem;
  /* border-left: 1px solid;
  border-color: var(--ion-color-primary-contrast); */
  right: 0;
  color: var(--ion-color-primary-contrast);
  margin: 0;
}
/* .rbc-timeslot-group {
  min-height: 80px;
} */
