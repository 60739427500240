ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 48px;
  bottom: 4px;
  cursor: pointer;
  background-color: var(--ion-color-light);
  padding: 8px;
  border-radius: 8px;
  color: var(--ion-color-contrast);
  /* box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); */
}
