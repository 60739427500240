#wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-background-color);
}
#login {
  box-shadow: 1px 1px 8px var(--ion-color-dark-contrast);
  background-color: var(--ion-card-background);
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  text-align: center;
}

#login .item {
  margin: 1.5rem 0;
}
#login .button {
  margin-top: 1rem;
  width: 100%;
}

#login ion-item {
  --background: var(--ion-card-background);
}
