.widget {
  text-align: center;
}
.min-height-widget {
  min-height: 83%;
}
.padding-bottom-widget {
  padding-bottom: 0.5rem;
}
.icon-widget {
  position: relative;
  top: 0.1rem;
  margin-left: 0.2rem;
}
.comparison-title-widget {
  margin-right: 0.2rem;
}
