ion-label {
  white-space: normal !important;
}
.cursor {
  cursor: pointer;
}

.alert-radio-label.sc-ion-alert-ios {
  white-space: normal !important;
}
.select-text {
  white-space: normal !important;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

.select-text {
  white-space: normal !important;
}
#properties-list .item-native {
  padding-inline-start: 0;
}
#select-product {
  color: var(--ion-color-primary);
}
ion-list#properties-list {
  /* border-right: 1px solid var(--ion-color-light); */
  background: none;
}
.required-fields-empty {
  position: "relative";
  bottom: "0.5rem";
  left: "1.4rem";
}
.pointer {
  cursor: pointer;
}
.flex-center {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}
.full-height {
  height: "100%";
}
.margin-right-icon {
  margin-right: ".2rem";
}
