.notes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notes-header > span {
  display: flex;
  align-items: center;
}
.notes-header h3 {
  margin: 0;
}
#noteList-empty {
  /* max-height: 19.2vh; */
  /* padding: 1rem; */
}
#noteList-min-height {
  /* max-height: 19.2vh; */
}
ion-card-content ion-item {
  --background: var(--ion-card-background);
}
.author {
  margin-left: 1rem;
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 8rem;
  height: 1.5rem;
  white-space: nowrap;
  margin-top: 0.4rem;
  border: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
}
input[readonly],
textarea[readonly] {
  color: var(--ion-color-medium-shade) !important;
}
