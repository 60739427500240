ion-card-header {
  padding-bottom: 0;
}
ion-card-subtitle {
  padding-bottom: 1rem;
}
.card-buble {
  border: 1px solid;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
}
