.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid var(--ion-color-light);
}
.modal-header ion-icon {
  cursor: pointer;
  color: var(--ion-color-primary);
}
.modal-header h1 {
  font-weight: bold;
  margin: 0;
}
.modal-content {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.modal-actions {
  padding: 0.5rem 1rem;
  /* box-shadow: -8px 0 8px var(--ion-color-light-shade); */
  border-top: 1px solid;
  border-color: var(--ion-color-light);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: var(--ion-color-light-shade); */
}

.modal-actions ion-icon {
  cursor: pointer;
}
.modal-actions .icons ion-icon {
  margin-right: 1rem;
  font-size: 24px;
  color: var(--ion-color-medium);
}

.modal-actions ion-button {
  margin-left: 1rem;
}
.modal-actions .button ion-icon {
  margin-right: 0.5rem;
  margin-left: 0;
}
.modal-actions .right {
  justify-content: end;
}
.modal-actions .left {
  justify-content: start;
}
.modal-tabs {
  margin-bottom: 2rem;
}
